<template>
    <div class="figuras-en-serie">
        <nav-bar sizetitle="sm" nivel="1"></nav-bar>
        <header-type-game title="Lenguaje"></header-type-game>
        <tutorial-game 
        v-if="tutorialStatus" 
        audio="tuto" 
        video="https://res.cloudinary.com/juegos-cramer/video/upload/v1619824888/juegos-cramer/tutoriales/nivel1/percepcion/letras_faltantes_vk8mkt.mp4"
        text="Marque las letras necesarias para rellenar los espacios vacios y poder leer la palabra"></tutorial-game>
        <div v-if="!tutorialStatus&&gameRuning" >
            <clock-down minuto="1" segundo="0" @setgameruning="setGameRuning()"></clock-down>
            <div class="lefa__game-box-container">
                <div class="lefa__game-box">
                    <div class="lefa__word-container">
                        <div 
                        v-for="(letter,index) in word"
                        :key="letter.id"
                        :class="['word__letter',letter=='?'?'interrogative_grid':'']"
                        >
                            <div 
                            :class="['word__interrogant',
                             index==respuesta_arr[0]||index==respuesta_arr[1]?'active__word':''
                            ]"
                            v-if="letter=='?'" 
                            @click="getKeyAns(index)"
                            >   
                              {{status_letter[index]&&index==correct_letter_index[0]||status_letter[index]&&index==correct_letter_index[1]?status_letter[index]:letter}}
                              <div 
                              :class="['lefa__check-answer',
                              index==index_answer_selected[0]||index==index_answer_selected[1]?checkAnswer:'']"></div>
                            </div>
                            <div v-else>{{letter}}</div>
                            
                        </div>
                    </div>
                    <div class="lefa__letters-container">
                        <div 
                        v-for="item  in alternatives"
                        :key="item.id"
                        class="lefa__game-alternatives"
                        @click="getLetter(item)"
                        >
                        {{item}}
                        </div>
                    </div>
                </div>
                <div v-if="capaProtectora" class="lefa__capa-protectora"></div>
            </div>
        </div>
        <div v-if="!gameRuning">
            <results-game nombrejuego="letras faltantes" nivel="1" inteligencia="lenguaje"></results-game>
        </div>
    </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import HeaderTypeGame from '@/components/HeaderTypeGame'
import TutorialGame from '@/components/TutorialGame'
import ClockDown from '@/components/ClockDown'
import ResultsGame from '@/components/ResultsGame'
import {mapState,mapMutations} from 'vuex'

export default {
    components:{
        NavBar,
        HeaderTypeGame,
        TutorialGame,
        ClockDown,
        ResultsGame,
    },
    data:()=>({
        puntos:0,
        serie:[],
        gameRuning:true,
        alternatives:[],
        word:[],
        visibleAnswer:false,
        checkAnswer:'',
        index_answer_selected:[],
        capaProtectora:false,
        respuesta_arr:[],
        palabra_arr:[],
        clave_arr:[],
        pivot:false,
        active_word:'',
        status_letter:{},
        correct_letter_index:[]
    }),
    computed:{
        ...mapState(['tutorialStatus'])
    },
    methods:{
         ...mapMutations(['changeMenuBarBottom','changeScoreGame','changeScoreDescription','changeTutorialStatus']),
       getKeyAns(index){
           this.pivot=!this.pivot
           
           if(this.pivot)
           {
               //console.log(index)
               this.active_word = 'active__word'
               this.respuesta_arr.push(index)
           }
           else{
               this.respuesta_arr=[]
               this.active_word = ''
           }
           //console.log(this.respuesta_arr)
           
       },
       getLetter(letter){
           if(this.pivot)
           {
               let indice = this.clave_arr.findIndex(e=>e.letra==letter)
               if(this.clave_arr){
                   if(indice==0||indice==1)
                   {
                       //console.log("ya casi")
                       this.puntos = this.puntos + 60;
                       let a = new Audio(require('../../../assets/juegos/audios/acierto.mp3'))
                       a.play()
                       this.checkAnswer = 'lefa__correct-answer'
                       this.status_letter[this.clave_arr[indice].pos]=letter
                       this.correct_letter_index.push(this.clave_arr[indice].pos)
                       this.index_answer_selected.push(this.clave_arr[indice].pos)
                     
                       this.clave_arr.splice(indice,1);
                       if(this.clave_arr.length==0)
                       {
                           this.puntos = this.puntos + 100;
                           //console.log("ganaste")
                           this.capaProtectora = true;
                           window.setTimeout(()=>{
                               this.gameGenerator()
                           },1000)
                           
                       }
                   }
                   else{
                       this.capaProtectora = true;
                       //console.log("error")
                       let a = new Audio(require('../../../assets/juegos/audios/fallo.mp3'))
                       a.play()
                       this.puntos = this.puntos - 20;
                       console.log(this.respuesta_arr)
                       this.index_answer_selected = this.respuesta_arr
                       this.checkAnswer = 'lefa__wrong-answer'
                       window.setTimeout(()=>{
                               this.gameGenerator()
                        },1000)
                   }

                   this.pivot=false;
               }else{
                   console.log("Ganaste");
               }
           }
       },
       setGameRuning(){
                this.gameRuning = false
                if(this.puntos<0)
                {
                    this.changeScoreGame(0)
                    this.changeScoreDescription('insuficiente')
                }
                else{
                    this.changeScoreGame(this.puntos)
                    if(this.puntos<=200){
                    this.changeScoreDescription('insuficiente')
                    }
                    else if(this.puntos<=400){
                        this.changeScoreDescription('escasa')
                    }
                    else if(this.puntos<=600){
                        this.changeScoreDescription('suficiente')
                    }
                    else if(this.puntos<=800){
                        this.changeScoreDescription('buena')
                    }
                    else{
                        this.changeScoreDescription('optima')
                    }
                }
            },
        getRandom(min, max) {
            return Math.floor(Math.random() * (max - min+1) ) + min;
        },
       getArrayRandom(min,max,cantidad){
            //let total = max-min;
            let res = [];
            while(!(res.length==cantidad))
            {
                let aux = this.getRandom(min,max);
                let a = res.find((e)=>e==aux)
                if(!a){
                    res.push(aux)
                }
            }
            return res;
        },
        reordenarArray(arr){
                let a = arr.sort(function() { return Math.random() - 0.5 })
            // console.log(a)
                return a;
            },
        gameGenerator(){
            this.capaProtectora = false;
            this.respuesta_arr=[]
            this.correct_letter_index = []
            this.checkAnswer=''
            this.status_letter = {}
            this.index_answer_selected = []
            let letras = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "Ñ", "O", "P", "Q", "R", "S", "T", "U", "V", "X", "Y", "Z"];
            
            let palabras = ["MAYORDOMO", "GABARDINA", "DIPLOMADO", "GEOGRAFIA", "CONCEJALA", "ALMACENAR", "FANTASMAL", "EXCELENTE", "PANECILLO", "OCURRENTE", "MATRICULA", "VOLCANICO", "PINCELADA", "EROSIONAR", "COLORANTE", "ALPARGATA", "DECADENTE", "ESTORNUDO", "COMPAÑERO", "ALGORITMO", "VESTUARIO", "PIRAMIDAL", "DENTADURA", "FRAGANCIA", "FURGONETA", "LAGARTIJA", "POLICIACO", "IZQUIERDA", "CLIENTELA"];
            let palabra_random = palabras[this.getRandom(0,28)]
            
            this.alternatives = letras;
            let cw = palabra_random.split("");
            this.word = [cw[0],cw[1],cw[2],cw[7],cw[8],cw[3],cw[6],cw[5],cw[4]]

            let arr_rand = this.getArrayRandom(0,8,2);
            let letra_1 = this.word[arr_rand[0]];
            let letra_2 = this.word[arr_rand[1]];

            this.word[arr_rand[0]] = '?'
            this.word[arr_rand[1]] = '?'
            
            let clave=[{
                        letra:letra_1,
                        pos:arr_rand[0]
                        },
                        {
                            letra:letra_2,
                            pos:arr_rand[1]
                        }
                    ]
            
            this.clave_arr = clave;
            
        }
    },
    mounted(){
        this.gameGenerator()
        this.changeMenuBarBottom(false)
        this.changeTutorialStatus(true)
    }
}
</script>
<style>
.figuras-en-serie{
    width: 100%;
    min-height: 100vh;
    background-color: #1F0275;
}
.lefa__game-box-container{
    width: 100%;
    margin-top:2em;
    display: flex;
    justify-content: center;
    position: relative;
}
.lefa__capa-protectora{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: red;
    opacity: 0;
}
.lefa__game-box{
    width: 90%;
    min-height: 53vh;
    background-color: #EEEFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.lefa__word-container{
    width: 15em;
    height: 15em;
    display: grid;
    grid-template-columns: repeat(3,33.3%);
    grid-template-rows: repeat(3,33.3%);
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}
.lefa__letters-container{
    width: 80%;
    height: 7em;
    display: grid;
    grid-template-columns: repeat(9,11.1%);
    grid-template-rows: repeat(3,33.3%);
}
.word__letter{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #86a133; 
    color:white;
    width: 95%;
    height: 95%;
    border-radius: 10px;
    font-size: 3em;
    position: relative;
    transition: all 0.5s;
}

.lefa__game-alternatives{
    background-color: #cddf99;
    width: 95%;
    height: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 1.4em;
    cursor: pointer;
    transition: all 0.5s;
}

.interrogative_grid{
    background-color: #d6b913;
    cursor: pointer;
}
.interrogative_grid:hover{
    transform: scale(0.9,0.9);
    
}
.word__interrogant{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.active__word{
    border-radius: 10px;
    background-color: #323232;
    transform: scale(0.9,0.9);
}
.lefa__wrong-answer{
    background-image: url('../../../assets/juegos/comunes/cancelar.svg');
}
.lefa__correct-answer{
    background-image: url('../../../assets/juegos/comunes/comprobado.svg');
}
.lefa__check-answer{
    position: absolute;
    width: 0.7em;
    height: 0.7em;
    top:0px;
    right: 0px;
}
@media (min-width: 900px) and (orientation: landscape) {
    .lefa__game-box{
        min-height:60vh;
        width: 60%;
        margin-bottom: 1em;
    }
    .lefa__game-alternatives:hover{
        transform: scale(0.9,0.9);
        outline: solid 1px #5e7223;
    }
}
</style>